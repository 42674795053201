@font-face {
  font-family: "OpenSans";
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Roboto";
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

:root {
  --open-sans-font: OpenSans;
  --open-sans-semibold-font: OpenSans-SemiBold;
  --open-sans-bold-font: OpenSans-Bold;
  --roboto-font: Roboto;

  --cultured: #f0f2f3;
  --eerie-black: #201e1f;
  --somewhat-gray: #444142;
  --sortof-gray: #a09d9e;
  --barely-gray: #e9e7e7;
  --forest-green: #d8fce9;
  --french-blue-disabled: #99d8ff;
  --french-blue-hover: #00588f;
  --french-blue: #0076c0;
  --international-orange: #ff5714;
  --peas-green: #3eef8f;
  --platinum: #e8e8e8;
  --rebecca-purple: #682fb1;
  --rose-madder: #e31836;
  --rose-madder-hover: #b40e27;
  --rose-madder-disabled: #ff91a1;
  --sheen-green: #9ce03e;
  --white: white;
}

body {
  background-color: var(--cultured);
  font-family: var(--open-sans-font);
}